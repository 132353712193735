<template>
  <div>
    <div class="wrapper">      
      <section id="content" class="d-flex flex-column">             
        <router-view class="pr-4" />
        <footer
          id="footer"
          class="text-muted text-right px-4 py-2 pb-2 flex-grow-0"
        >         
        </footer>
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src


export default {

  methods: {
    
  },
  sockets: {
    
  },
  mounted() {
    
  },
};
</script>


<style lang="scss">

</style>