<template>
  <div>
    <template v-if="ready">
      <div :class="inline ? 'd-flex align-items-baseline ' : ''">
        <label :class="inline ? 'mr-2' : ''">{{ label }}</label>
        <b-form-select
          :value="value"
          :options="selectOptions"
          :state="normError"
          @input="$emit('input', $event)"
          @change="$emit('selectChanged', $event)"
          :disabled="disabled"
        >
          <template #first>
            <!-- <b-form-select-option :value="null"
            >-- Selecione --</b-form-select-option
          > -->
          </template>
        </b-form-select>
        <b-form-invalid-feedback :id="id">
          <span class="text-small text-red" v-if="errorMsg">
            {{ errorMsg }}
          </span>
          <span class="text-small text-red" v-else>
            O campo {{ label }} é obrigatório.
          </span>
        </b-form-invalid-feedback>
      </div>
    </template>
    <template class="mb-3" v-else>
      <div v-if="inline">
        <b-skeleton type="input"></b-skeleton>
      </div>
      <div v-else>
        <b-skeleton class="mb-3" width="120px"></b-skeleton>
        <b-skeleton type="input"></b-skeleton>
      </div>
    </template>
  </div>
</template>

<script>
import crypto from "crypto";
export default {
  props: [
    "value",
    "disabled",
    "label",
    "error",
    "errorMsg",
    "selectOptions",
    "ready",
    "inline",
  ],
  data() {
    return {
      id: "",
    };
  },
  computed: {
    normError() {
      if (this.error) {
        return false;
      }
    },
  },
  mounted() {
    this.id = crypto.randomBytes(8).toString("hex");
  },
};
</script>

<style>
</style>