<template>
  <div>
    <hr />
    <div class="d-flex justify-content-end">
      <div class="d-flex mr-4">
        <span class="text-muted small mr-3 pt-2">Items por página</span>
        <FormSelect
          v-if="ready"
          v-model="value.limit"
          :selectOptions="[
            { text: '10', value: 10 },
            { text: '20', value: 20 },
            { text: '50', value: 50 },
            { text: '100', value: 100 },
          ]"
          :ready="true"
          @selectChanged="change_limit"
        />
        <div v-else class="mb-3" style="width: 70px">
          <b-skeleton type="input" />
        </div>
      </div>
      <div class="d-flex ml-4">
        <span class="text-muted small mr-3 pt-2">Página</span>
        <div v-if="ready">
          <b-pagination
            v-model="value.page"
            :total-rows="value.count"
            :per-page="value.limit"
            aria-controls="my-table"
            @change="change_page"
          ></b-pagination>
        </div>
        <div v-else class="mb-3" style="width: 300px">
          <b-skeleton type="input" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    ready: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    change_page(v) {
      const query = this.$route.query;
      this.$router.push({
        query: { ...query, page: v, limit: this.value.limit },
      });
      this.$emit("change_pagination");
    },
    change_limit(v) {
      const query = this.$route.query;
      this.$router.push({
        query: { ...query, page: this.value.page, limit: v },
      });
      this.$emit("change_pagination");
    },
  },
};
</script>

<style>
</style>