import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import FormInput from '@/components/FormInput'
import FormSelect from '@/components/FormSelect'
import FormDatepicker from '@/components/FormDatepicker'
import TableList from '@/components/TableList'
import Pagination from '@/components/Pagination'
import './plugins'
import './mixin'

Vue.config.productionTip = false

Vue.component('FormInput', FormInput)
Vue.component('FormSelect', FormSelect)
Vue.component('FormDatepicker', FormDatepicker)
Vue.component('TableList', TableList)
Vue.component('Pagination', Pagination)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
