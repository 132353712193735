const rootPath = 'rematricula'
export default
    [
        {
            path: `${rootPath}/:unityId`,
            name: 'reenroll',            
            component: () => import('@/views/reEnrolls/Index.vue')
        },
        {
            path: `${rootPath}/:unityId/politica_de_privacidade`,
            name: 'reenroll',            
            component: () => import('@/views/reEnrolls/Politica.vue')
        }
    ]
