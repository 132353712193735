import _ from 'lodash'
import Http from 'Http'
const state = {
    sentList: [],
}
const actions = {
    
}
const mutations = {
    ['SET']: (state, data) => {
        state[data.state] = data.data
    },
    set_periods: (state, periods) => {

        state.periods = periods
    },
    set_groups: (state, groups) => {

        state.groups = groups
    },
    set_client: (state, client) => {

        state.client = client
    },
    set_estados: (state, estados) => {

        state.estados = estados
    },
    set_data: (state, data) => {

        state.data = data
    }
}

export default {
    namespaced: true,
    state,

    actions,
    mutations
}