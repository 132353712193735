// import Vue from 'vue'

// import { BootstrapVue } from 'bootstrap-vue'

// // import '../styles/app.scss'

// Vue.use(BootstrapVue)

import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)