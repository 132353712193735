import _ from "lodash";
import Http from "Http";
const state = {
  data: {},
  paymentDates: [],
};
const actions = {
  async initApp({ commit }, id) {
    try {
      const data = await Http().get(`/token-confirm-reserve/${id}`);

      console.log(data);

      if (data) {
        commit("SET_STATE", { state: "data", data: data.data });
      }

      return true;
    } catch (e) {
      console.log(e);

      return false;
    }
  },
  async save_reservation({}, model) {
    let data = {};

    try {
      const saveData = await Http().post("/reserve/confirm", model);

      console.log(saveData);

      if (saveData.data.status == 200) {
        data = saveData.data.payload;
      }
    } catch (e) {
      console.log("enrollment-form-confirm submit error", e);
    }

    return data;
  },
};
const mutations = {
  ["SET_STATE"]: (state, data) => {
    state[data.state] = data.data;
  },
  set_periods: (state, periods) => {
    state.periods = periods;
  },
  set_groups: (state, groups) => {
    state.groups = groups;
  },

  set_client: (state, client) => {
    state.client = client;
  },
  set_estados: (state, estados) => {
    state.estados = estados;
  },
  set_data: (state, data) => {
    state.data = data;
  },
};

export default {
  namespaced: true,
  state,

  actions,
  mutations,
};
