import axios from 'axios'

export default () => {

    const api =  axios.create({

        baseURL: 'https://api-boletos.apiserver.com.br',
       // baseURL: 'http://localhost:3011',
        withCredentials: false,
        'Access-Control-Allow-Credentials':true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'           
        }
    })   

    return api;

}