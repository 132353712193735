<template>
  <div>
    <div v-if="ready">
      <b-table
        class="mb-0 mt-3"
        :small="size == 'regular' ? false : true"
        striped
        hover
        v-if="items.length > 0"
        :fields="fields"
        :items="items || []"
      >
        <template
          v-for="slot in Object.keys($scopedSlots)"
          v-slot:[toCellName(slot)]="props"
        >
          <slot v-bind="props" :name="slot" />
        </template>
      </b-table>
      <div v-else class="mb-0">
        <hr />
        <div class="d-flex justify-content-center">
          <div
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <!-- <img src="@/assets/bee.png" alt="" width="15%" /> -->
            <span class="lead">Desculpe! Não encontramos nada...</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mt-3">
      <b-skeleton-table
        :rows="10"
        :columns="fields.length"
        :table-props="{ hover: true, striped: true }"
        v-if="!ready"
      ></b-skeleton-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    fields: {
      type: Array,
      default: [],
    },
    size: String,
    ready: Boolean,
  },
  methods: {
    toCellName(slot) {
      return `cell(${slot})`;
    },
  },
};
</script>

<style></style>
