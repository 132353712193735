import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/Index.vue'
import { _ } from 'core-js'

const childrenRoutes = [];

const manualInjectModules = ['reEnrolls', 'confirmReservation', 'sender']

const requireModules = require.context('@/views', true, /\.js$/);

requireModules.keys().forEach(fileName => {

  const folders = fileName.split('/')

  if (folders[folders.length - 1] == 'index.js' && folders[folders.length - 2] == 'router') {

    if (manualInjectModules.includes(folders[1])) {

      childrenRoutes.push(...requireModules(fileName).default)

    }
  }
})

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name:'rootRoutes',
    component: Index,
    children: [
      ...childrenRoutes
    ]
  },  
  // {
  //   path: '*',
  //   redirect: { name: 'dashboard' }
  // }
]

const router = new VueRouter({
  mode: 'history',  
  routes
})


export default router

