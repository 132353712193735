import _ from 'lodash'
import Http from 'Http'
const state = {
    data: {},
    client: {},
    estados: {},
    periods: [],
    groups: [],

}
const actions = {
    async initApp({ commit }, id) {

        console.log('init store')

        try {

            const unity = await Http().post(`/token-unity`, { id })

            console.log(unity)
            
            if (unity.data.status == 200) {

                commit('set_groups', unity.data.payload.group)

                const estados = await Http().get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')

                let normalized_estados = []

                _.forEach(estados.data, (estado) => {
                    normalized_estados.push({ description: estado.sigla, value: estado.sigla, id: estado.id })
                })

                commit('set_estados', normalized_estados)

                commit('set_data', unity.data.payload)

                return true

            } else {

                this.$router.push(`/not_found`)

            }

        } catch (e) {

            console.log(e)

            return false

        }
    },
    async get_periods({ commit }, groupId) {

        commit('SET', { state: 'periods', data: [] })

        const response = await Http().get(`/plan/group/${groupId}`)

        console.log(response)

        commit('SET', { state: 'periods', data: response.data })
    },
    async saveForm({ }, model) {

        console.log(model)

        const saveData = await Http().post(`/reserve`, model)

        console.log('data', saveData)

        return saveData
    }
}
const mutations = {
    ['SET']: (state, data) => {
        state[data.state] = data.data
    },
    set_periods: (state, periods) => {

        state.periods = periods
    },
    set_groups: (state, groups) => {

        state.groups = groups
    },
    set_client: (state, client) => {

        state.client = client
    },
    set_estados: (state, estados) => {

        state.estados = estados
    },
    set_data: (state, data) => {

        state.data = data
    }
}

export default {
    namespaced: true,
    state,

    actions,
    mutations
}