export default {
  sent: [
    {
      key: "cliente",
      label: "Cliente",
      thStyle: {
        width: "30%",
      },
      thClass: "text-muted small",
      tdClass: "align-middle",
    },
    {
      key: "accepted",
      label: "Email",
      thStyle: {
        width: "30%",
      },
      thClass: "text-muted small",
      tdClass: "align-middle",
    },
    {
      key: "created_at",
      label: "Enviado em",
      thStyle: {
        width: "15%",
      },
      thClass: "text-muted small",
      tdClass: "align-middle",
    },

    {
      key: "contrato",
      label: "Contrato",
      thStyle: {
        width: "15%",
      },
      thClass: "text-muted small",
      tdClass: "align-middle",
    },
  ],
  error: [
    {
      key: "client",
      label: "Cliente",
      thStyle: {
        width: "30%",
      },
      thClass: "text-muted small",
      tdClass: "align-middle",
    },
    {
      key: "error",
      label: "Motivo",
      thStyle: {
        width: "30%",
      },
      thClass: "text-muted small",
      tdClass: "align-middle",
    },
    {
      key: "file",
      label: "Nº Arquivo",
      thStyle: {
        width: "15%",
      },
      thClass: "text-muted small",
      tdClass: "align-middle",
    },
    {
      key: "contract",
      label: "Contrato",
      thStyle: {
        width: "15%",
      },
      thClass: "text-muted small",
      tdClass: "align-middle",
    },
  ],
  file: [
    {
      key: "cliente",
      label: "Cliente",
      thStyle: {
        width: "30%",
      },
      thClass: "text-muted small",
      tdClass: "align-middle",
    },
    {
      key: "email",
      label: "Email",
      thStyle: {
        width: "30%",
      },
      thClass: "text-muted small",
      tdClass: "align-middle",
    },
    {
      key: "documento",
      label: "Documento",
      thStyle: {
        width: "15%",
      },
      thClass: "text-muted small",
      tdClass: "align-middle",
    },
    {
      key: "vencimento",
      label: "Vencimento",
      thStyle: {
        width: "15%",
      },
      thClass: "text-muted small",
      tdClass: "align-middle",
    },
    {
      key: "contrato",
      label: "Contrato",
      thStyle: {
        width: "15%",
      },
      thClass: "text-muted small",
      tdClass: "align-middle",
    },
  ],
};
